import { usePushWebSettingsStore } from '../storage/settings';

export const pushOn = (timeout?: number) => {
  const configurationJaiminho = usePushWebSettingsStore.getState();

  return new Promise((resolve, reject) => {
    if (typeof timeout === 'undefined') {
      timeout = configurationJaiminho.settings.options.pushOnTimeout;
    }

    setTimeout(() => {
      if (configurationJaiminho.settings.isInitialized) {
        // Criar variavel que mostra quando renderizar
        resolve('Finish initialized components');
      } else {
        throw new Error('Push Web: Subscription not initialized.');
      }
    }, timeout);
  });
};
