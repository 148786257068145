import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { pageNames } from '@common/routes/pageNames';

const MyDebts = lazy(() => import('@dues/pages/MyDebts'));
const Confirmation = lazy(() => import('@dues/pages/Partner/Confirmation/ConfirmationV2'));
const Grouping = lazy(() => import('@dues/pages/Partner/Grouping'));
const ClosedAgreement = lazy(() => import('@dues/pages/Partner/ClosedAgreement'));
const ClosedAgreementError = lazy(() => import('@dues/pages/Partner/ClosedAgreementError'));

const MyAgreements = lazy(() => import('@dues/pages/MyAgreements'));
const MyAgreementsDetail = lazy(() => import('@dues/pages/MyAgreements/MyAgreementsDetail'));
const MyAgreementsDetailConditions = lazy(() => import('@dues/pages/MyAgreements/MyAgreementsDetail/components/MyAgreementsDetailConditions'));
const MyAgreementsDetailCompositions = lazy(() => import('@dues/pages/MyAgreements/MyAgreementsDetail/components/MyAgreementsDetailCompositions'));

const Offers = lazy(() => import('@dues/pages/Offers'));
const PreApprovedOffers = lazy(() => import('@dues/pages/PreApprovedOffers'));

export function Router() {
  return (
    <>
      <Route path={pageNames.myDebts.path} element={<MyDebts />} />
      <Route path={pageNames.grouping.path} element={<Grouping />} />
      <Route path={pageNames.confirmation.path} element={<Confirmation />} />
      <Route path={pageNames.agreementClosed.path} element={<ClosedAgreement />} />
      <Route path={pageNames.agreementClosedError.path} element={<ClosedAgreementError />} />
      <Route path={pageNames.offers.path} element={<Offers />} />
      <Route path={pageNames.preApprovedOffers.path} element={<PreApprovedOffers />} />
      <Route path={pageNames.myAgreements.path} element={<MyAgreements />} />
      <Route path={pageNames.myAgreementsDetail.path} element={<MyAgreementsDetail />} />
      <Route path={pageNames.myAgreementsDetailConditions.path} element={<MyAgreementsDetailConditions />} />
      <Route path={pageNames.myAgreementsDetailCompositions.path} element={<MyAgreementsDetailCompositions />} />
    </>
  );
}
