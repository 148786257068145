import { useUserStore } from '@common/store/useUserStore';
import { AgreementResponseProps } from '@dues/apis/agreements/types';
import useApiAgreements from '@dues/apis/agreements/useApiAgreements';
import { useRedline } from '@libs/redline';
import React, { createContext, useEffect, useState } from 'react';

interface RenegContextType {}

export const RenegContext = createContext<any>({
  agreements: undefined,
});

export const RenegProvider = ({ children }: any) => {
  const user = useUserStore((state) => state.user); // <<<

  const { track: trackRedline } = useRedline();
  const { apiGetAgreements } = useApiAgreements();

  const [agreements, setAgreements] = useState<AgreementResponseProps[] | [] | undefined>();
  const [agreementsLoading, setAgreementsLoading] = useState(false);

  const getAgreements = () => {
    if(agreements) return;

    setAgreementsLoading(true);

    apiGetAgreements
      .send()
      .then((responseData: AgreementResponseProps[]) => {
        const isResponseArray = Array.isArray(responseData);
        const agreementsList = isResponseArray ? responseData : [];

        setAgreements(agreementsList);
        setAgreementsLoading(false);
      })
      .catch((error: any) => {
        trackRedline.agreements.agreementListingErrored({
          errorDetails: error?.statusText,
          errorMessage: error?.message,
          errorType: error?.status,
        });

        console.error('[GET AGREEMENTS]:', error);
        setAgreementsLoading(false);
      });
  };

  useEffect(() => getAgreements(), []);

  return (
    <RenegContext.Provider
      value={{
        agreements,
        agreementsLoading,
      }}
    >
      {children}
    </RenegContext.Provider>
  );
};
