import useUserAuthentication from '@admission/hooks/useUserAuthentication';
import useApiLoginEnrichment from '@common/apis/useAPILoginEnrichment';
import PageLoader from '@common/components/PageLoader';
import constants from '@common/constants';
import { DebtsProvider } from '@common/contexts/DebtsContext/DebtsProvider';
import useAuth from '@common/hooks/useAuth';
import useGTM from '@common/hooks/useGTM';
import usePushNotification from '@common/hooks/usePushNotification';
import { useUserStore } from '@common/store/useUserStore';
import PrivatePageTemplate from '@common/templates/PrivatePage';
import Conditional from '@libs/atlas/components/misc/Conditional';
import { JaiminhoClient } from '@libs/jaiminho';
import { useRedline } from '@libs/redline';
import { base64Decrypt } from '@libs/utils/helpers/base64';
import { getCookie } from '@libs/utils/helpers/getSafeCookies';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import './styles.scss';

import { RenegProvider } from '@common/contexts/RenegContext/RenegProvider';

export default function PrivateRoutes() {
  const user = useUserStore((state) => state.user);
  const { addCookieRLTracksAuth, setRLTracksAuthCookie } = useUserAuthentication();
  const { apiLoginEnrichment } = useApiLoginEnrichment();
  const { isAuthenticated } = useAuth();
  const { track: trackPushNotification } = usePushNotification();
  const { track: trackRedline } = useRedline();
  const GTM = useGTM();
  const pushOptions = {
    pushOnTimeout: 15000,
    promptTitle: '',
    promptMessage: 'Olá, você gostaria de ser avisado sobre novas ofertas?',
    promptButton: 'Sim',
    promptDismiss: 'Não',
    bell: false,
    button: false,
    welcome: true,
    welcomeTitle: 'Seja bem vindo!',
    welcomeBody: 'Obrigado por aceitar as notificações.',
    welcomeIcon: 'https://app.acordocerto.com.br/assets/img/acordocerto.png',
    welcomeBadge: 'https://app.acordocerto.com.br/assets/img/acordocerto.png',
  };

  useEffect(() => {
    const getDeskToken = getCookie("ATENDE_TOKEN");
    if(getDeskToken) {
      const bodyHtml = document.querySelector('body');
      bodyHtml?.classList.add('atende-flow')
      bodyHtml?.style?.setProperty('overflow', '')
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      trackPushNotification(user);
      GTM.trigger.loginWithSuccess();

      const authCookie = getCookie(constants.redline.rl_tracks_auth, false);
      if (authCookie) {
        const rLTracksAuthData = JSON.parse(base64Decrypt(authCookie));

        apiLoginEnrichment.send();

        trackRedline.userAuthentication.userAuthenticated({
          customerId: user.documento,
          correlationId: rLTracksAuthData.correlationId,
          authenticationType: rLTracksAuthData.authenticationType,
        });

        addCookieRLTracksAuth('cached-sign-in');
      } else {
        setRLTracksAuthCookie();
      }

      trackRedline.userAuthentication.userIdentified({
        document: user.documento,
      });
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return <PageLoader />;
  }

  return (
    <Conditional
      condition={!!isAuthenticated}
      renderIf={
        <RenegProvider>
          <DebtsProvider>
            <PrivatePageTemplate
              firstName={user.primeiroNome}
              fullName={user.nome}
            >
              <Outlet />
              <JaiminhoClient
                apiKey={import.meta.env.VITE_PUSH_WEB_API_KEY}
                apiPath={import.meta.env.VITE_PUSH_WEB_API_PATH}
                marketplaceApi={import.meta.env.VITE_AC_API}
                applicationServerKey={import.meta.env.VITE_PUSH_WEB_SERVER_KEY}
                options={pushOptions}
              />
            </PrivatePageTemplate>
          </DebtsProvider>
        </RenegProvider>
      }
    />
  );
}
