// hooks
import { usePushWebSettingsStore } from '../../storage/settings';

export const useWidgetBellHook = () => {
  const { settings: configurationJaiminho } = usePushWebSettingsStore();
  const isVisibleBell = configurationJaiminho.options.prompt
    ? false
    : !configurationJaiminho.options.bell || configurationJaiminho.isSubscribed
    ? false
    : true;

  return {
    isVisible: configurationJaiminho.isInitialized ? isVisibleBell : false,
    margin: configurationJaiminho.options.margin,
    backgroundImage: configurationJaiminho.options.bellBackgroundImage,
  };
};
