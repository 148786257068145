// Components
import { WidgetBell } from './components/WidgetBell';
import { WidgetPrompt } from './components/WidgetPrompt';

// Types
import type { JaiminhoClientTypes } from './types';

// hooks
import { usePushWebSettingsStore } from './storage/settings';
import { useEffect } from 'react';

// utils
import { isSubscriptionStorage } from './utils/isSubscriptionStorage';
import { subscribePushWeb } from './utils/subscribe';
import { convertBase64ToUnitInt } from './utils/convertBase64ToUnitInt';
import { shouldPromptAgain } from './utils/shouldPromptAgain';

declare const window: {
  pwRegistration: ServiceWorkerRegistration;
} & Window;

export const JaiminhoClient = ({
  apiKey,
  apiPath,
  applicationServerKey,
  marketplaceApi = '',
  options = {},
}: JaiminhoClientTypes) => {
  const { handleSetSettings, settings: configurationJaiminho } =
    usePushWebSettingsStore();

  // Functions
  const handleGetSubscription = async () => {
    try {
      if (!('Notification' in window))
        return console.error(
          'Push Web: This browser does not support desktop notification.'
        );

      let permission = Notification.permission;
      if (permission !== 'granted') {
        permission = await Notification.requestPermission();
      }

      if (['denied', 'default'].includes(permission)) {
        handleSetSettings({
          isInitialized: shouldPromptAgain(
            configurationJaiminho.options.promptFrequency
          ),
        });

        return console.error('Push Web: The user has blocked notifications.');
      }

      const serviceWorkerRegistration = await navigator.serviceWorker.ready;
      const subscription =
        await serviceWorkerRegistration.pushManager.getSubscription();

      if (!subscription)
        return handleSetSettings({
          isInitialized: true,
        });

      handleSetSettings({
        isSubscribed: true,
        isInitialized: true,
      });

      const hasSubscriptionStorage = await isSubscriptionStorage();
      if (hasSubscriptionStorage) return;

      const hasUnsubscribe = await subscription.unsubscribe();

      if (!hasUnsubscribe)
        return console.error('Push Web: Unsubscription failed');

      handleSetSettings({
        isSubscribed: false,
      });

      await subscribePushWeb();
    } catch (err) {
      console.error(`Push Web: Get Subscription ${err}`);
    }
  };

  /// Start e instalo o SW
  const startServiceWoker = async () => {
    if (!('serviceWorker' in navigator))
      return console.error('Push Web: Service worker not supported.');

    try {
      const swRegistration = await navigator.serviceWorker.register(
        '/service-worker.js'
      );

      window.pwRegistration = swRegistration;

      handleSetSettings(options);
      await handleGetSubscription();

      console.log(
        `ServiceWorker registration successful with scope: ${swRegistration.scope}`
      );

      await swRegistration.update();
    } catch (err) {
      console.error('Push Web: ServiceWorker registration failed: ', err);
    }
  };

  useEffect(() => {
    if (applicationServerKey != undefined && applicationServerKey.length > 0) {
      const serverKey = convertBase64ToUnitInt(applicationServerKey);

      handleSetSettings({
        applicationServerKey: serverKey,
        apiKey,
        apiPath,
        marketplaceApi,
        isInitialized: false,
      });
    }

    startServiceWoker();
  }, []);

  return (
    <>
      <WidgetBell position="left" />
      <WidgetPrompt {...options} />
    </>
  );
};
