import constants from '@common/constants';
import { legacyBase64Decrypt } from '@libs/utils/helpers/base64';
import { addCookie } from '@libs/utils/helpers/getSafeCookies';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function useAtendeAuth() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const atendeLoginToken = searchParams.get('atende');

  function isAtendeLogin() {
    console.log('isAtendeLogin 1');
    if (atendeLoginToken) {
      console.log('isAtendeLogin 2');
      const tokenAtende = legacyBase64Decrypt(atendeLoginToken);
      const dataAtende = JSON.parse(tokenAtende);
      const oneHundredTwentyDays = new Date();
      oneHundredTwentyDays.setDate(oneHundredTwentyDays.getDate() + 120);
      console.log('tokenAtende', tokenAtende);

      addCookie({
        key: constants.legacyAuth.TOKEN,
        value: dataAtende.auth.access_token,
        expires: oneHundredTwentyDays,
      });

      addCookie({
        key: constants.legacyAuth.SUBJECT,
        value: JSON.stringify(dataAtende.user),
        encrypt: true,
        expires: oneHundredTwentyDays,
      });

      addCookie({
        key: 'ATENDE_TOKEN',
        value: atendeLoginToken,
      });
    }
  }

  useEffect(() => {
    isAtendeLogin();
  }, []);
}
